import React from "react";
import Home from "../fragments/Home/Home";
import Helmet from "react-helmet";
import useMetadata from "../hooks/useMetadata";
import useViewport from "../hooks/useViewport";
import useSeoQuery from "../queries/useSeoQuery"
import Seo from "../components/seo"

const IndexPage = () => {
  const { title, description } = useMetadata();
  const seo = useSeoQuery()
  useViewport();

  return (
    <>
      <Helmet>
        <html lang="es" />
        <title>{title}</title>
        <meta name="description" content={description}></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x"
          crossOrigin="anonymous"
        ></link>
      </Helmet>
      <Seo
        title="Home"
        lang="es"
        titleSEO={seo.homeTitleSeo}
        description={seo.homeDescriptionSeo.homeDescriptionSeo}
      />

      <Home />
    </>
  );
};

export default IndexPage;
