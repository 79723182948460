import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "gatsby-plugin-transition-link/AniLink";
import { BsChevronDoubleDown } from "react-icons/bs";
import HomeXs from "./HomeXs";
import "./Home.scss";

const useImageshome = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomeFotos {
        edges {
          node {
            fotos {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  return data.allContentfulHomeFotos.edges[0].node.fotos;
};

const Home = () => {
  const data = useImageshome();
  const [img, setImg] = useState(null);

  useEffect(() => {
    (() => {
      const a = data[Math.floor(Math.random() * data.length)];
      setImg(a);
    })();
  }, [data]);

  return (
    <>
      <div className="home-container">
        <Link
          swipe
          direction="up"
          duration={3}
          to="/proyectos"
          className="transition-link"
        >
          <GatsbyImage
            image={getImage(img)}
            alt=""
            className="home-background-image"
          />
        </Link>
        <div className="name-contact-home">
          <h4>ESTUDIO REBUELTA DOMECQ</h4>
        </div>
        <div className="icon-swipe" style={{ backgroundColor: "transparent" }}>
          <BsChevronDoubleDown
            fontSize="3rem"
            color="#fff"
            style={{ backgroundColor: "transparent" }}
          />
        </div>
        <HomeXs />
      </div>
    </>
  );
};

export default Home;
