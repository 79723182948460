import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MenuXs from "../../components/Menu/MenuXs/MenuXs";
import { BsChevronDoubleDown } from "react-icons/bs";
import "./HomeXs.scss";

const useImagesHomeMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomeFotos {
        edges {
          node {
            fotosMobile {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  return data.allContentfulHomeFotos.edges[0].node.fotosMobile;
};

const HomeXs = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [animationEnd, setAnimationEnd] = useState(false);
  const data = useImagesHomeMobile();
  const [img, setImg] = useState(null);

  let handlers = useSwipeable({
    onSwipedUp: () => setShowMenu(true),
    trackTouch: showMenu && animationEnd ? false : true,
    trackMouse: true,
  });

  useEffect(() => {
    (() => {
      const a = data[Math.floor(Math.random() * data.length)];
      setImg(a);
    })();
  }, [data]);

  useEffect(() => {
    if (!showMenu) {
      setAnimationEnd(false);
    }
  }, [showMenu]);

  return (
    <>
      <div className="father-HomeXs" {...handlers}>
        <div className="HomeXs" onClick={() => setShowMenu(true)}>
          <GatsbyImage
            image={getImage(img)}
            alt=""
            className="home-xs-background-image"
          />
          <h1 className="text-home-name">
            ESTUDIO <br /> REBUELTA DOMECQ
          </h1>
          <div
            className="icon-swipe-xs"
            style={{ backgroundColor: "transparent" }}
          >
            <BsChevronDoubleDown
              fontSize="2.5rem"
              color="#fff"
              style={{ backgroundColor: "transparent" }}
            />
          </div>
        </div>
        {showMenu && (
          <MenuXs
            extraClassName="animation-up"
            defaultState={true}
            onCloseCb={() => setShowMenu(false)}
            onAnimationEnd={() => setAnimationEnd(true)}
          />
        )}
      </div>
    </>
  );
};

export default HomeXs;
